import statisticsApi from '@/_modules/statistics/api/statistics.api';
import i18n from '@/i18n';

export default class Statistics {

  static track({ eventId, actionName, data }) {
    return statisticsApi.putStat({ eventId, actionName, data });
  }

  static promopageView({ eventId, externalId, contactId }, { to, from }) {
    eventId = eventId ? Number(eventId) : undefined;
    contactId = contactId ? Number(contactId) : undefined;
    externalId = externalId ? ('' + externalId) : undefined;

    if (!eventId || !externalId) {
      return;
    }
    const data = Object.assign(Statistics.getCommonParams({ to, from }), {
      external_id: externalId,
      contact_id: contactId || undefined, /* optional */
    });

    return Statistics.track({
      eventId: eventId,
      actionName: 'promopage_view',
      data
    });
  }

  static programSessionView({ eventId, programSessionId }, { to, from }) {
    eventId = eventId ? Number(eventId) : undefined;
    programSessionId = programSessionId ? Number(programSessionId) : undefined;
    if (!eventId || !programSessionId) {
      return;
    }
    const data = Object.assign(Statistics.getCommonParams({ to, from }), {
      program_session_id: programSessionId
    });

    return Statistics.track({
      eventId: eventId,
      actionName: 'program_session_view',
      data
    });
  }

  static createEventStat({ eventId, referrerUrl, userId }, { to, from }) {
    eventId = eventId ? Number(eventId) : undefined;
    referrerUrl = referrerUrl ? referrerUrl : undefined;
    if (!eventId || !referrerUrl) {
      return;
    }
    const data = Object.assign(Statistics.getCommonParams({ to, from }), {
      referrerUrl: referrerUrl,
      userId: userId,
    });

    return Statistics.track({
      eventId: eventId,
      actionName: 'create_event',
      data
    });
  }

  static eventViewStat({ eventId, browser, timeZone, contactId, userId, url }, { to, from }) {
    eventId = eventId ? Number(eventId) : undefined;
    if (!eventId) {
      return;
    }
    const data = Object.assign(Statistics.getCommonParams({ to, from }), {
      eventId: eventId,
      browser: browser,
      timeZone: timeZone,
      contactId: contactId,
      userId: userId,
      url: url
    });

    return Statistics.track({
      eventId: eventId,
      actionName: 'event_view',
      data
    });
  }

  static contactView({ eventId, contactId, externalId }, { to, from }) {
    eventId = eventId ? Number(eventId) : undefined;
    contactId = contactId ? Number(contactId) : undefined;
    externalId = externalId ? ('' + externalId) : undefined;

    if (!eventId || !contactId) {
      return;
    }
    const data = Object.assign(Statistics.getCommonParams({ to, from }), {
      contact_id: contactId,
      external_id: externalId || undefined, /* optional */
    });

    return Statistics.track({
      eventId: eventId,
      actionName: 'contact_view',
      data,
    });
  }

  static companyVideoChatRoomView({ eventId, contactId, externalId, isRoomCreated }, { to, from }) {
    eventId = eventId ? Number(eventId) : undefined;
    contactId = contactId ? Number(contactId) : undefined;
    externalId = externalId ? ('' + externalId) : undefined;

    if (!eventId || !contactId) {
      return;
    }
    const data = Object.assign(Statistics.getCommonParams({ to, from }), {
      viewer_contact_id: contactId,
      external_id: externalId || undefined, /* optional */
      is_room_created: isRoomCreated || undefined, /* not sending if it is just a view, not creation */
    });

    return Statistics.track({
      eventId: eventId,
      actionName: 'company_video_chat_room_view',
      data,
    });
  }

  static addEventToFavorites({ eventId, userId, flowName, actionName }, { to, from }) {
    eventId = eventId ? Number(eventId) : 0;
    userId = userId ? Number(userId) : 0;
    if (!eventId) {
      return;
    }
    const data = Object.assign(Statistics.getCommonParams({ to, from }), {
      userId,
      widgetMethod: flowName,
      platform: 'web',
    });

    return Statistics.track({
      eventId: eventId,
      actionName: actionName || 'add_fav_event',
      data,
    });
  }

  static getCommonParams({ to, from }) {
    const params = {};
    const lang = i18n.locale;
    if (lang) {
      params.lang = lang;
    }
    if (to) {
      params.to = {
        name: to.name,
        path: to.path,
        params: to.params,
      };
    }
    if (from) {
      params.from = {
        name: from.name,
        path: from.path,
        params: from.params,
      };
    }
    return params;
  }

}
