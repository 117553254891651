import { TContact } from '@/_types/contact.type';
import { TCountry } from '@/_types/country.type';
import { TCity } from '@/_types/city.type';

// TODO: refactoring - split into separate files

export enum EventAccessType {
  FREE = 'free',
  REGISTER = 'register',
  TICKET = 'ticket',
  INVITE = 'invite',
  CASH = 'cash'
}

export type TEventType = {
  id?: number;
  name?: string;
}

export type TCategory ={
  id?: number;
  name?: string;
}

export type TPersonalData = {
  notes?: number;
  contacts?: number;
  tickets?: number;
  is_creator?: boolean;
  is_favorite?: boolean;
  is_going?: boolean;
  has_access?: boolean;
}

export type TEventMap = {
  country_name?: string;
  city_name?: string;
  location?: string;
  longitude?: string;
  latitude?: string;
}

export type TEvent = {
  id?: number;
  title: string;
  descr?: string;
  image?: string;
  date_start: Date;
  date_end: Date;
  url?: string;
  buy_ticket_url?: string;
  buy_booth_url?: string;
  photos?: string[];
  address?: string;
  country?: TCountry;
  city?: TCity;
  agendas?: string;
  org_name?: string;
  org_phone?: string;
  org_email?: string;
  org_logo?: string;
  access_type?: EventAccessType;
  event_type?: TEventType;
  categories?: TCategory[];
  personal?: TPersonalData;
  is_private?: boolean;
  is_enabled?: boolean;
  map?: TEventMap;
  creator_user_id?: number;
  languages?: string[];
  event_domain?: string;
  time_region: string;
  editors?: TContact[];
  updated_at?: string;
  created_at?: string;
  map_image?: string;
  slug?: string;
}
